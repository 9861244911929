import React from "react"
import {
  ScGridRow,
  ScGridCol,
  ScH1,
  ScText,
  ScTextLink,
  ScPageWrapper,
} from "../components/StyledComponents/elements"
import MauraImg from "../components/common/MauraImg"
import SEO from "../components/seo"

function About() {
  return (
    <ScPageWrapper>
      <SEO title="About" />
      <ScGridRow>
        <ScGridCol span={6}>
          <MauraImg />
        </ScGridCol>
        <ScGridCol span={6}>
          <ScGridRow>
            <ScH1>About Cricket</ScH1>
          </ScGridRow>

          <ScGridRow>
            <ScText fontSize="data-text">
              <span style={{ fontSize: "2rem", fontFamily: "Caveat Brush" }}>
                Cricket Casey
              </span>{" "}
              is an author as well as a qualified educator in the field of
              body-mind fitness. She is a graduate of New York University with a
              degree in physical therapy. Additionally, she has a degree in
              biopsychology from William Paterson University.
            </ScText>
          </ScGridRow>
          <ScGridRow>
            <ScText fontSize="data-text">
              She has worked as a pediatric physical therapist both privately
              and in the New York City public school system, as well as in
              private preschools. In her parallel career as a personal trainer,
              Casey has produced four fitness videos distributed nationally and
              cited in numerous newspapers and magazines, including the National
              Physical Therapy Bulletin and the Wall Street Journal. She is
              presently developing a Biofitness training program to teach young
              people how to stretch and strengthen the proximal core muscles
              that build beautiful and healthy posture.
            </ScText>
          </ScGridRow>
          <ScGridRow>
            <ScText fontSize="data-text">
              She is a firm believer in an artistic team effort combining music
              and literature to stimulate both sides of the brain, and to make
              reading an educational as well as entertaining experience for
              children. Visit <ScTextLink>http://cricketcasey.com/ </ScTextLink>{" "}
              for updated information.
            </ScText>
          </ScGridRow>
        </ScGridCol>
      </ScGridRow>
    </ScPageWrapper>
  )
}

export default About
